import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";

import { useState } from 'react';
import isEmail from "validator/lib/isEmail";
import axios from 'axios';

import StNavbar from "../components/StNavbar";
import StFooter from "../components/StFooter";

import '../App2.css';
import { FormControl } from "react-bootstrap";

function StContactUs() {

    const initialState = {
        name: "", 
        email: "",
        message: ""
    };

    // Form validation state.
    const invalidResult = -2;
    const errorResult = -1;
    const successResult = 0;

    const [validated, setValidated] = useState(false);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [resultState, setResultState] = useState(invalidResult);

    // Contents of the form inputs.
    const reInitState = () => {
        setFormElements({
            "name": "",
            "email": "",
            "message": ""
        });
        
    }

    const [formElements, setFormElements] = useState({
        "name": "",
        "email": "",
        "message": ""    
    });

    const {name, email, message} = formElements;

    const ttl = document.querySelector('title');
    ttl.innerText = 'Contact Us'; 

    const mta = document.querySelector("meta[name='description']");
    mta.setAttribute('content', 'Contact TurboSteed for any assistance');
    

    const handleOnChange = (event) => {
        
        // Use ...formElements to retain the existing values and overwrite only
        // whats new.
        setFormElements(
            {
                ...formElements,
                [event.target.name]: event.target.value
            }
        );

    }

    const handleOnSubmit = async (event) => {

        setFormSubmitted(true);

        // Prevent the page from refreshing.
        event.preventDefault();

        try {

            if ((name === undefined) || (name.length == 0) ||
                (email === undefined) || (email.length == 0) ||
                (message === undefined) || (message.length == 0)) {
                    console.log("Something is not valid");

                    event.preventDefault();
                    event.stopPropagation();
                    setValidated(false);
                    setResultMessage('Please enter valid data');
                    return;
            }

            if (!isEmail(email)) {

                event.preventDefault();
                event.stopPropagation();
                setValidated(false);
                setResultMessage('Please enter valid data');
                return;
            }

            if (message.length > 200) {
                event.preventDefault();
                event.stopPropagation();
                setValidated(false);
                setResultMessage('Please enter valid data');
                return;
            }

            setValidated(true);

            const pubId = '5536fd5b5062a7ba7e330484553f1157';
            const pubKey = '9311ca20a73c04250b3abb8a0c5cad62e8e44e966984a1aa9f8ff17ac82f11b5';
            const pubEmail = 'sales@turbosteed.com';

            const cfUrl = "https://api-us.turbosteed.com/contactForms/" + pubId;

            const subForm = {
                method: 'post',
                url: cfUrl,
                headers: {
                    'content-type': 'application/json',
                },
                auth: {
                    username: pubEmail,
                    password: pubKey
                },
                data: {
                    formData: {
                        formName: name,
                        formEmail: email,
                        formMessage: message
                    }
                }
            };


            const axres = await axios(subForm);

            if (axres.status != 200) {
                setResultMessage('Something went wrong, please try again');
                setResultState(errorResult);
            }
            else {

                setResultMessage('Thank you, we will get in touch with you!');
                setResultState(successResult);
            }
        }
        catch (e) {
            console.log(e);
            setResultMessage('Sorry, something went wrong, please try again');
        }
            
        // Now, change the values back to zero.
        reInitState();
    }

    return (
        <div>
            <StNavbar />
                <Container className="fluid">
                    <Row>
                        
                        <Col className='mt-2 pt-2'>
                            <h1>Get in touch with us</h1>
                            <p>Email: <a href="mailto:turbosteed.com">sales@turbosteed.com</a></p>
                        </Col>
                        
                        
                         <Col>   
                            {
                                resultMessage.length? 
                                    ((resultState == invalidResult)? null :
                                        ((resultState == successResult)? <p className="mt-2 pt-2 h6 text-success">{resultMessage}</p>: 
                                        <p className="mt-2 pt-2 h6 text-warning">{resultMessage}</p>)
                                    ):null
                            }
                                <Form className='contact-form' onSubmit={handleOnSubmit}>
                                    <Form.Group className="mt-4 pt-4 mt-4" controlId="contactUs.name">
                                        <Form.Label className="h6 mx-4 d-flex">Name</Form.Label>
                                        <Form.Control className="mx-4 contact-form-elements" type="text" onChange={handleOnChange} value={name} name='name' 
                                          required maxLength={(50)} placeholder="Maximum 50 characters" />
                                            
                                    </Form.Group>
                                    <Form.Group className="mt-4 mt-4" controlId="contactUs.email">
                                        <Form.Label className="h6 mx-4 d-flex">Email</Form.Label>
                                        <Form.Control className="mx-4 contact-form-elements" type="email" onChange={handleOnChange} value={email} name='email' required 
                                         placeholder="Maximum 50 characters" maxLength={(50)} />
                                
                                    </Form.Group>
                                    <Form.Group className="mt-4" controlId="contactUs.message">
                                        <Form.Label className="h6 mx-4 d-flex">Message</Form.Label>
                                        <Form.Control className="mx-4 contact-form-elements" as="textarea" rows={5} placeholder="Maximum 200 characters" onChange={handleOnChange} 
                                        value={message} name='message' required maxLength={(200)} />
                                    </Form.Group>
                                    <Button className="mx-4 mt-4" type="submit">Submit</Button>
                                    
                                </Form>
                               
                            </Col>

                    </Row>

                </Container>

            <StFooter />

        </div>
    );
}

export default StContactUs;