import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Image from "react-bootstrap/Image";
import  Badge from "react-bootstrap/Badge";


import StFooter from "../components/StFooter";
import StNavbar from "../components/StNavbar";

                            
const StBlogHome = () => {
                       
    const ttl = document.querySelector('title');
    ttl.innerText = 'TurboSteed Blogs'; 

    const mta = document.querySelector("meta[name='description']");
    mta.setAttribute('content', 'TurboSteed case studies, how-to articles, use cases and tutorials');

    return (            
        <div>               
            <StNavbar />
            <div className='mt-0 mb-5 gradient-new-theme jumbotron-blog'>
                <Container className='flex-lg-row align-items-center'>
                    <Row className=''>
                        <Col className="jumbotron-text">
                            <div className="h1 mx-5">TurboSteed Blog</div>
                            <div className="h2 mx-5">Your go-to resource for insights and tips on hosting</div>
                           
                        </Col>
                    
                    </Row>
                </Container>
            </div>
                        
                        
            <Container className="d-flex flex-wrap my-5 ">
                <Card style={{width: "20rem"}} className="mx-auto">
                            
                    <Container className="d-flex flex-row my-2">
                        <Badge className="my-2 mx-1" bg="primary">HowTo</Badge>
                    </Container>
                            
                    <Card.Body className="d-flex flex-column mt-auto">
                        <Card.Title><p className="h2">Quick & easy static website hosting</p></Card.Title>
                        <Card.Text className="my-4">
                            <span className="h6">A tutorial for anyone who wants an online presence</span>
                        </Card.Text>
                        <Card.Footer className="mt-auto p-0 border-0 bg-white">
                            <Button className="mt-auto" href="/blogs/host-static-websites">Learn more</Button>
                        </Card.Footer>
                    </Card.Body>

                </Card>


                <Card style={{width: "20rem"}} className="mx-auto">

                    <Container className="d-flex flex-row my-2">
                        <Badge className="my-2 mx-1" bg="primary">HowTo</Badge>
                    </Container>

                    <Card.Body  className="d-flex flex-column">
                        <Card.Title><p className="h2">Host Single Page Applications</p></Card.Title>
                        <Card.Text className="my-4">
                            <span className="h6">A tutorial to host Single page React applications</span>

                        </Card.Text>
                        <Card.Footer className="mt-auto p-0 border-0  bg-white">
                            <Button className="mt-auto" href="/blogs/host-react-apps">Learn more</Button>
                        </Card.Footer>
                    </Card.Body>

                </Card>


                <Card style={{width: "20rem"}} className="mx-auto">

                    <Container className="d-flex flex-row my-2">
                        <Badge className="my-2 mx-1" bg="primary">HowTo</Badge>
                    </Container>

                    <Card.Body className="d-flex flex-column">
                        <Card.Title><p className="h2">Share your work profile</p></Card.Title>
                        <Card.Text className="my-4">
                            <span className="h6">Create your professional profile and share it with your contacts</span>
                        </Card.Text>
                        <Card.Footer className="mt-auto p-0 border-0  bg-white">
                            <Button className="mt-auto" href="#">Coming Soon</Button>
                        </Card.Footer>
                    </Card.Body>

                </Card>


            </Container>

            <StFooter />
        </div>

    );

}

export default StBlogHome;
