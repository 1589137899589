import { Routes } from "react-router-dom";
import { Route } from "react-router-dom";



import StBlogsHome from "./StBlogsHome";
import StNavbar from "../components/StNavbar";
import StBlog from "./StBlog";

const StBlogs = () => {
    return (            
        <div>               
                {/* <StNavbar /> */}
            <Routes>
                <Route path="/" element={<StBlogsHome />} />
                <Route path="/:blogName" element={<StBlog />} />

            </Routes>
        </div>
    );

}

export default StBlogs;