
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';

import { TextConfig } from '../GlobalConfig';

function StNavbar() {
    return (
    
        <div className=''>
            <Navbar expand="lg" className="">
                <Container fluid className="">
                    <Navbar.Brand href="/#home">{TextConfig.brandName}</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link className="navlink-texts" href="/#home">Home</Nav.Link>
                            <Nav.Link className="navlink-texts" href="/#features">Features</Nav.Link>
                            <Nav.Link className="navlink-texts" href="/#pricing">Pricing</Nav.Link>
                            <Nav.Link className="navlink-texts" href="/blogs">Blogs</Nav.Link>
                            <Nav.Link className="navlink-texts" href="/contactus">Contact Us</Nav.Link>
                        </Nav>
                        <Nav className="ml-auto ">
                            <Button className="primary" href="https://dashboard.turbosteed.com">Login</Button>
                        </Nav>

                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    
    )
}

export default StNavbar;
