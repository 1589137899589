export const TextConfig = {

	"brandName": "TurboSteed",
	
	"heroTitle": "Static website hosting",
	"heroDescription": "Upload your static websites and get running in minutes",

	"personas": [
		{
			"usecase": "Host Static Websites",
			"persona": "Ideal for small businesses, marketing pages or personal projects",
			"image": "hosting-16-9.jpeg",
			
		},
		{
			"usecase": "Serve Single Page Apps",
			"persona": "Ideal for use cases such as dashboards, prototypes or internal business applications",
			"image": "hosted-app-16-9.jpeg",

		},
		{
			"usecase": "Showcase Your Work",
			"persona": "Ideal for students, developers or designers",
			"image": "hosting-700-700.svg",
			"image": "showcase-16-9.jpeg",
			
		}
	],
	"hiddenValue": {
		"value": "We manage the hardware, storage, network, security and scale",
		"how": "So that you can focus on your core business",
	},
	"benefits": [
		{
			"feature": "Faster user experience",
			"value": "We will serve your website using a global Content Delivery Network"		
		},
		{
			"feature": "Custom Domains",
			"value": "Give your website a professional feel with custom domain"		
		},
		{
			"feature": "Built-in Analytics",
			"value": "Analyze your site traffic and modify your content for maximum reach"		
		},
		{
			"feature": "No platform lock-in",
			"value": "You are free to use your own toolset, libraries or packages"		
		},
		{
			"feature": "Built-in Certificate",
			"value": "Protect your site and data in transit without worries"		
		},
		{
			"feature": "Contact Forms ",
			"value": "Gather user feedback and access them on your dashboard"		
		},
	],
	"extraBenifit" : {
		"key": "Free for 5000 website hits",
		"value": "You pay once your website starts getting traction"
	
	},

	"pricing3col": [
		{
			"plan": "Free Plan",
			"price": "$0 per month",
			"planFeatures": [
				"1 active project",
				"5 MB total size",
				"Basic Analytics",
				"5000 hits per month",
				"1 custom domain (addon)"
			],
			"subscriptionLink": {
				"buttonName": "Beta Access",
				"buttonURL": "https://dashboard.turbosteed.com"
			}
		},
		{
			"plan": "Basic Plan",
			"price": "$5 per month",
			"planFeatures": [
				"2 active projects",
				"25 MB total size",
				"Basic Analytics",
				"25000 hits per month",
				"2 custom domains",
			],
			"subscriptionLink": {
				"buttonName": "Contact Us",
				"buttonURL": "/contactus"
			}
		},
		{
			"plan": "Startup plan",
			"price": "$15 per month",
			"planFeatures": [
				"5 active projects",
				"150 MB total size",
				"Advanced Analytics",
				"150000 hits per month",
				"5 custom domains",
				"Custom 404 page"
			],
			"subscriptionLink": {
				"buttonName": "Contact Us",
				"buttonURL": "/contactus"
			}
		},

	
	],

	"secondaryValueProp": "Scale without worried",
	"secondaryValuePropDescription": "TurboSteed provieds you a hassle free, scalable and reliable platform so that you can focus on your work"
};

export const BrandName = "TurboSteed";
