import Container from "react-bootstrap/Container";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function StFooter() {
    return (

        <div>
            <Container fluid className="d-flex justify-content-center mt-0 mb-1 pt-5 footer-container">
                <Row className="mt-2 mb-0 pt-2">
                    <Col>
                        <p className="h5 pb-1">TurboSteed</p>
                        {/*}
                        <p>About</p>
                        <p>Talent</p>
                        */}
                    </Col>
                    {/*}
                    <Col>
                        <p className="h6 pb-1">Applications</p>
                        <p>Single page HTML websites</p>
                        <p>File hosting</p>
                        <p>PDF links</p>
                        <p>Single Page Applications (SPA)</p>
                    </Col>
                    <Col>
                        <p className="h6 pb-1">Use Cases</p>
                        <p>Marketing Flyers</p>
                        <p>Blogs</p>
                        <p>Landing pages</p>
                        <p>Helpcenter articles</p>
                        <p>Resumes</p>
                        <p>Portfolios</p>
                    </Col>
                    */}
                    <Col>
                        <p className="h5 pb-1">Resources</p>
                        <p><a href="mailto:support@turbosteed.com">Support</a></p>
                        
                        <p><a href="/terms.txt">Terms</a></p>
                        {/*<p>FAQs</p> */}
                        
                        <p><a href="/blogs">Blogs</a></p>

                    </Col>
                </Row>
            </Container>
        </div>

    );

}

export default StFooter;