import Container from 'react-bootstrap/Container';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import ListGroup from 'react-bootstrap/ListGroup';
import ListGroupItem from 'react-bootstrap/ListGroupItem';
import Image from "react-bootstrap/Image";

import { BrandName, TextConfig } from './GlobalConfig';
import { useEffect } from 'react';

import './App2.css'

import StNavbar from './components/StNavbar';
import StFooter from './components/StFooter';

	function StLanding() {

		useEffect(() => {
			const anchor = window.location.hash.slice(1);
			if (anchor) {
				const anchorEl = document.getElementById(anchor);
				if (anchorEl) {
					anchorEl.scrollIntoView();
				}
			}
		}, []);

		return (
			
			<div>

				<StNavbar />	

				<div className='mt-0 mb-5 gradient-new-theme jumbotron'>
					<Container className='flex-lg-row align-items-center'> 
						<Row className=''>
							<Col className="jumbotron-text">
								<div className="h1 mx-5">{TextConfig.heroTitle}</div>
								{/*<div className="h2 mx-5">{TextConfig.heroDescription}</div> */}
								<h2 className="h3 mx-5">{TextConfig.heroDescription}</h2>
								<Button className="mx-5 mt-3 button-comple2-background" href="https://dashboard.turbosteed.com">Start Hosting</Button>
						</Col>
						<Col className="jumbotron-hero">
							<Image src="/hero-image-4.svg" alt="Benifies of using TurboSteed to host your static website" className="img-fluid" />
						</Col>
						</Row>
					</Container>
				</div>

				<div id="features">
					<Container className="flex-wrap pt-5 pb-5">
						<Row xs={1} md={3} className="g-4">
							{TextConfig.personas.map((persona) => (
								<Col key={Math.random()} className="d-flex align-items-stretch">
									<Card className="px-4 py-4 d-flex flex-column">
									{/* <Card.Img variant="top" src={persona.image} /> */}
									<Image src={persona.image} style={{ objectFit: 'cover'}} alt="persona" />
										<Card.Body className="d-flex flex-column align-items-center">
											<Card.Title>
												<h3 className="h4">{persona.usecase}</h3>
											</Card.Title>
											<Card.Text>
												<span className="h5 text-secondary mt-2">{persona.persona}</span>
											</Card.Text>

										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>						
					</Container>
				</div>    


				{/* <div className='mt-5 mb-5 landing-color-2 minitron-2 d-flex align-items-center text-center'> */}
				<div className='mt-5 mb-5 landing-color-2 minitron-2 d-flex align-items-center text-center'>
					<Container fluid>
						<h3 className="h3">{TextConfig.hiddenValue.value}</h3>
						<p className="h5">{TextConfig.hiddenValue.how}</p>
					</Container>
				</div>			


				<div>
					<Container className="pt-5 mt-5 pb-5 mb-5 flex-wrap">
						<Row xs={1} md={3} className="g-4">
							{TextConfig.benefits.map((benefit) => (
								/* <Col key={Math.random()} className="d-flex align-items-stretch"> */
								<Col key={Math.random()} className="d-flex">
									<Card className="px-4 d-flex flex-column">
										<Card.Body className="d-flex flex-column align-items-center">
											<Card.Title>
												<h3 className="h4">{benefit.feature}</h3>
											</Card.Title>
											<Card.Text>
												<span className="h5 text-secondary mt-2">{benefit.value}</span>
											</Card.Text>

										</Card.Body>
									</Card>
								</Col>
							))}
						</Row>						
					</Container>
				</div>    

{/*}
				<div className='mt-5 mb-0 landing-color-2 minitron-2 d-flex align-items-center text-center'>
					<Container fluid>
						<h3 className="h3">{TextConfig.extraBenifit.key}</h3>
						<p className="h5">{TextConfig.extraBenifit.value}</p>
					</Container>
				</div>	
*/}				

				<div id="pricing" className="pricing-margins">
					<Container fluid className="border-0 pt-5 pb-5 m5 gradient-new-theme text-light ">
						<Row className="h3 d-flex justify-content-md-center pb-3">Pricing</Row>
						
						<Container className='mt-5 mb-5'>
							<Row xs={1} md={3} className='g-4'>
							
								{TextConfig.pricing3col.map((plan) => (
									<Col key={Math.random()} className="">
										<Card className="d-flex flex-column p-2 border h-100 background-light">
											<Card.Title className="text-center pt-3 price">
												<h3>{plan.plan}</h3>
											</Card.Title>
											<p className="h4 text-center price"> {plan.price} </p>
											<div className="text-center pb-3 mt-3">
													<Button className="text-center" href={plan.subscriptionLink.buttonURL}>{plan.subscriptionLink.buttonName}</Button>
											</div>

											<ListGroup className=''>
												{plan.planFeatures.map((featLine) => (
													<ListGroupItem key={Math.random()} className="p-2 m-2 text-center">
														{featLine}
													</ListGroupItem>

												))}
											</ListGroup>
												
												
											
											{/*<Card.Footer className="d-flex flex-column">
												
											</Card.Footer>*/}
										</Card>
									</Col>



								))}
								
							
							</Row>
						</Container>

					</Container>
				</div>


				<StFooter />

			</div>
		);
	}

	export default StLanding;
