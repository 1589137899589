import React from 'react';
import ReactDOM from 'react-dom/client';

import reportWebVitals from './reportWebVitals';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";


import './index.css';
import App from './App';
import StError from './pages/StError';
import StContactUs from './pages/StContactUs';
import StBlogHome from './pages/StBlogsHome';

import StBlogs from "./pages/StBlogs";

// Setup the router for the website
const turboRouter = createBrowserRouter(
  [
    {
      errorElement: <StError />,

      children: [
        {
          path: "/",
          element: <App />,
          errorElement: <StError />
        },
        {
          path: "/contactus",
          element: <StContactUs />,
          errorElement: <StError />
        },
        {
          path: "/blogs/*",
          //element: <StBlogHome />,
          element: <StBlogs />,
          errorElement: <StError />
        }

      ]

    }

  ]

);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={turboRouter} />
  </React.StrictMode>
);



// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
